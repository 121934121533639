import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';

export interface iHCSItems
{
    ITEMNMBR: string;
    ITEMDESC: string;
    SDS: boolean;
    Label: boolean;
}

interface iDocument
{
    Id: string;
    Id2: string;
    DocType: string;
    DocId: number;
    DocName: string;
    DocDescription: string;
    LastUpdated: string;
    DateCreated:string;
    DocDate: string;
    Id3: string;
    DownloadInsteadOfDisplay: boolean;
    AzurePath: string;
    DocContent: string[];
    FileExists: boolean;
    encrypted: string;
    decrypted: string;
}

@Injectable({
  providedIn: 'root'
})

export class HcsService {

  constructor(private http: HttpClient) { }

  appAPIUrl = environment.appAPIUrl;
  harfilesAPIUrl = environment.harfilesAPIUrl;

  getItems(searchkey: string): Observable<iHCSItems[]> {
    const url = `${this.appAPIUrl}/dotcom/GetItems?searchkey=` + searchkey;
    return this.http.post<iHCSItems[]>(url, null);
  }

  GetProductSheetForItem(item: string, docType: string): Observable<iDocument[]> {
    const url = `${this.harfilesAPIUrl}/docs/getDocument`;
    return this.http.post<iDocument[]>(url, { DocType: docType, Id: '0', Id2: item, Id3: '' })
  }
}