  <div class="container">
    <div class="row" style="width: 100%; margin-top: 20px;">
      <div class="col-3">
        <img src="../../../assets/HCS_Logo.png" style="height: 75px; margin-left: -170px;">
      </div>
      <div class="col-9" style="align-items: center; margin-top: 27px;">
        <table style="width: 70%; align-items: center;">
            <tr>
                <td style="width: 120px;">&nbsp;</td>
                <td style="text-align: left;"><a href="https://www.polyon.com" target="_blank">Polyon</a></td>
                <td style="text-align: left;"><a href="https://harrellscoatingssolutions.com" target="_blank">Products</a></td>
                <td style="text-align: left;"><a href="https://www.harrells.com/Careers" target="_blank">Careers</a></td>
                <!-- <td style="text-align: left;"><a href="https://harrellscoatingssolutions.com/Content/Harrells_General_Terms_and_Conditions_2021_International.pdf" target="_blank">Terms & Conditions</a></td> -->
                <td style="text-align: left;"><a (click)="GetPDF('HCSTerms', 'miscellaneous')">Terms & Conditions</a></td>
            </tr>
        </table>
      </div>
      <div>
        <table style="width: 100%; align-items: center; margin: 30px;">
          <tr><td style="text-align: center;"><h2>Our Products</h2></td></tr>
          <tr><td style="text-align: center;">
            <form [formGroup]="searchForm">
              <mat-form-field appearance="outline" style="width: 300px; height: 25px; margin-left: 0px;">
                <mat-label>Search Products</mat-label>
                <input matInput #searchInput formControlName="searchInputControl" type="text" [(ngModel)]="searchValue" (keyup)="GetItems()">
              </mat-form-field>
            </form>
            <img src="../../../../assets/search.png" style="margin-left: 340px; margin-top: -40px; height: 25px; width: 25px; cursor: pointer;" (click)="GetItems()">
          </td></tr>
        </table>
        </div>
        <div class="scroll-grid">
        <table mat-table #table [dataSource]="matTableSource" multiTemplateDataRows class="mat-elevation-z8 w-100" style="margin-top: 30px;">
          <ng-container matColumnDef="ITEMNMBR">
            <th mat-header-cell *matHeaderCellDef style="width: 200px !important;"> Item # </th>
            <td mat-cell *matCellDef="let element"> {{ element.ITEMNMBR }} </td>
          </ng-container>
        
          <ng-container matColumnDef="ITEMDESC">
            <th mat-header-cell *matHeaderCellDef style="width: 600px !important;">&nbsp;&nbsp;Web Desc </th>
            <td mat-cell *matCellDef="let element">&nbsp;&nbsp;{{ element.ITEMDESC }} </td>
          </ng-container>

          <ng-container matColumnDef="SDS">
            <th mat-header-cell mat-header-cell *matHeaderCellDef style="width: 350px;">&nbsp;&nbsp;SDS </th>
            <td mat-cell *matCellDef="let element">&nbsp;&nbsp;
              <img *ngIf="element.SDS" src="../../../assets/pdf.png" style="width: 25px; height: 25px; cursor: pointer;" (click)="GetPDF(element.ITEMNMBR, 'HCSItemSDS')">&nbsp;&nbsp;
            </td>
          </ng-container>

          <ng-container matColumnDef="Label">
            <th mat-header-cell mat-header-cell *matHeaderCellDef style="width: 350px;">&nbsp;&nbsp;Label</th>
            <td mat-cell *matCellDef="let element">&nbsp;&nbsp;
              <img *ngIf="element.Label" src="../../../assets/pdf.png" style="width: 25px; height: 25px; cursor: pointer;" (click)="GetPDF(element.ITEMNMBR, 'HCSItemLabel')">&nbsp;&nbsp;
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            [ngClass]="{hovered: element.hovered, highlighted: selection.isSelected(element)}"
            (mouseover)="element.hovered = true" (mouseout)="element.hovered = false">
          </tr>
        </table>
      </div>
    </div>
    </div>