import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from 'src/app/environments/environment';
import { iHCSItems } from 'src/app/services/hcs.service';
import { HcsService } from 'src/app/services/hcs.service'
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-hcs-main',
  templateUrl: './hcs-main.component.html',
  styleUrls: ['./hcs-main.component.css']
})

export class HcsMainComponent implements OnInit {

  constructor(public hcsService: HcsService, public formBuilder: FormBuilder) { }

  searchForm = this.formBuilder.group({
    searchInputControl: [null]
  });

  harfilesAPIUrl = environment.harfilesAPIUrl;
  selection = new SelectionModel<iHCSItems>(false, []);
  items: iHCSItems[] = [];
  matTableSource: MatTableDataSource<iHCSItems> | null;
  displayedColumns: string[] = ['ITEMNMBR', 'ITEMDESC', 'SDS', 'Label'];
  searchValue: string = "";
  
  ngOnInit(): void {
    this.GetItems();
  }

  GetItems(){
    this.items = [];
    this.hcsService.getItems(this.searchValue).subscribe((items) => {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        this.items.push({ 
          ITEMNMBR: element.ITEMNMBR,
          ITEMDESC: element.ITEMDESC,
          SDS: element.SDS,
          Label: element.Label
        });
      };
      this.matTableSource = new MatTableDataSource<iHCSItems>(this.items);
    });
  }

  GetPDF(inItem: string, docType: string){
    this.hcsService.GetProductSheetForItem(inItem, docType).subscribe((response) => {
      if (response.length == 0) {
        alert("PDF not found for item number " + inItem + ".");
      }else{
        window.open(response[0].DocName, "_blank");
      }
    });
  }
}