import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HcsMainComponent } from './components/hcs-main/hcs-main.component';

const routes: Routes = [ 
  { path: '', component: HcsMainComponent },
  { path: '**', component: HcsMainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
